/* App.css */

/* Centering and base styles */
.App {
  text-align: center;
}

/* Logo animation */
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Header styling */
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

/* Background gradient animation */
.animated-background {
  background: linear-gradient(270deg, #0b0b0b, #2c2c2c, #1a1a1a);
  background-size: 400% 400%;
  animation: backgroundAnimation 15s ease infinite;
}

@keyframes backgroundAnimation {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}

/* Parallax layer for depth effect */
.parallax-layer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  transform: translateZ(-1px) scale(1.5);
  z-index: -1;
  pointer-events: none;
}
