/* Background for the main page */
.storefront-container {
    min-height: 100vh;
    background-image: url('https://firebasestorage.googleapis.com/v0/b/monitorapp-7e92a.appspot.com/o/it-network-background.jpg?alt=media&token=34d524c8-22f6-481d-a5ca-103798363d2e');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    margin: 0;
    padding: 20px;
    border-radius: 10px;
}

/* Pricing Toggle */
.pricing-toggle {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    gap: 10px;
}

.toggle-button {
    padding: 10px 20px;
    margin: 0 10px;
    background-color: #f0f0f0;
    border: none;
    cursor: pointer;
    font-weight: bold;
    transition: background-color 0.3s ease;
    border-radius: 5px;
}

.toggle-button.active {
    background-color: #007bff;
    color: white;
}

/* Navbar Styling */
.navbar {
    position: absolute;
    top: 20px;
    left: 20px;
    display: flex;
    gap: 10px;
}

.nav-button {
    background-color: #4CAF50;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.nav-button:hover {
    background-color: #45a049;
}

/* Center the content */
.header, .plans-section {
    text-align: center;
}

/* Style the header */
.header h1 {
    font-size: 2.5rem;
    margin-bottom: 10px;
    color: white;
}

.header p {
    font-size: 1.2rem;
    margin-bottom: 40px;
    color: white;
}

/* Video Container */
.video-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    padding-bottom: 0; /* Maintain 16:9 Aspect Ratio */
    height: 720px;
    width: 1080px;
    max-width: 1280px; /* Set a max width for the video container */
    margin: auto;
    background-color: transparent; /* Remove the background color */
}

.video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none; /* Ensure no border around the iframe */
}

/* Align Login/Signup buttons */
.auth-buttons {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-bottom: 10px;
}

.pricing-toggle {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-bottom: 20px;
}

/* Style the plans section */
.plans-section {
    margin: 0 auto;
    max-width: 1200px;
}

/* Flexbox layout for plan cards */
.plans-container {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    gap: 20px;
}

/* Style for individual plan cards */
.plan-card {
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 8px;
    padding: 20px;
    max-width: 300px;
    flex: 1;
    color: #fff;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    transition: transform 0.3s ease;
}

.plan-card:hover {
    transform: scale(1.05);
}

.plan-card h3 {
    font-size: 1.5rem;
    margin-bottom: 10px;
}

.plan-card p.price {
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 20px;
}

.plan-card ul {
    list-style: none;
    padding: 0;
    margin-bottom: 20px;
}

.plan-card ul li {
    margin-bottom: 10px;
}

.btn {
    padding: 10px 20px;
    font-size: 1rem;
    font-weight: bold;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

.btn:hover {
    opacity: 0.9;
}

.premium-button {
    background-color: #f39c12;
    color: #fff;
}

.professional-button {
    background-color: #3498db;
    color: #fff;
}

.disabled {
    background-color: gray;
    color: #fff;
    cursor: not-allowed;
}

/* Ensure cards wrap properly on smaller screens */
@media (max-width: 768px) {
    .plans-container {
        flex-direction: column;
        align-items: center;
    }

    .plan-card {
        margin-bottom: 20px;
    }
}

/* Centering content and applying styles for auth/dashboard/cancel pages */
.auth-container, .dashboard-container, .cancel-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    text-align: center;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.6); /* Optional: Overlay for readability */
    padding: 20px;
    border-radius: 10px;
    max-width: 500px;
    margin: auto;
}

.auth-form input {
    margin-bottom: 10px;
    padding: 10px;
    width: 100%;
    max-width: 300px;
    border-radius: 5px;
    border: 1px solid #ccc;
}

.auth-button {
    padding: 10px 20px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.auth-button:hover {
    background-color: #45a049;
}

/* Center the content of the dashboard */
.dashboard-container {
    text-align: center;
    color: #fff;
}

.dashboard-actions button {
    margin: 10px;
    padding: 10px 20px;
    background-color: #2196F3;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.dashboard-actions button:hover {
    background-color: #1E88E5;
}

.cancel-container {
    text-align: center;
    color: #fff;
}

.cancel-container p {
    margin: 20px 0;
}

/* Green status for active subscription */
.subscription-status-active {
    color: #4CAF50;
    font-weight: bold;
}

/* Red status for cancelled subscription */
.subscription-status-cancelled {
    color: #FF0000;
    font-weight: bold;
}
