.camo-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
}

.camo-tier {
    position: relative;
    width: 500px;
    height: 80px;
    border-radius: 16px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #000000;
    border: 0px;
}

.overlay {
    position: absolute;
    top: 5px;
    left: 5px;
    width: calc(100% - 10px);
    height: calc(100% - 10px);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 15px;
    box-sizing: border-box;
    border-radius: 8px;
    background-color: rgba(0, 0, 0, 0.7); /* Slightly darker overlay */
    border: 2px solid rgba(255, 255, 255, 0.3); /* Light border to separate overlay from background */
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.6); /* Optional shadow for more distinction */
}

/* Title and text */
.camo-title {
    font-size: 24px;
    font-weight: bold;
    text-transform: uppercase;
    color: #ffffff;
}

.progress-text {
    font-size: 24px;
    color: #52ff5a;
}

/* Backgrounds */
.gold-theme, .diamond-theme, .darkspine-theme, .darkmatter-theme {
    background-repeat: repeat-x;
    background-size: auto 100%;
    animation: seamlessScroll 120s linear infinite;
}

.gold-theme {
    background-image: url('../assets/images/Gold.png');
}
.diamond-theme {
    background-image: url('../assets/images/Diamond.png');
}
.darkspine-theme {
    background-image: url('../assets/images/DarkSpine.png');
}
.darkmatter-theme {
    background-image: url('../assets/images/DarkMatter.png');
}

@keyframes seamlessScroll {
    0% { background-position: 0 0; }
    100% { background-position: -5000px 0; }
}
